import Q from "q";
import moment from "moment";

// 返回旅游公告类型枚举
import web_common_TourismMall_getNoticeTypeEnum from "@/lib/data-service/default/web_common_TourismMall_getNoticeTypeEnum";
// 详情
import web_common_TourismMall_getNoticeDetail from "@/lib/data-service/default/web_common_TourismMall_getNoticeDetail";

export default {
    data() {
        return {
            details: {},
            noticeType: 1, // 公告类型   1：最新公告    2：重要公告   3：系统公告
            noticeTypeList: [
                {label: '最新公告', value: 1},
                {label: '重要公告', value: 2},
                {label: '系统公告', value: 3}
            ]
        }
    },
    methods: {
        back() {
            const __this = this;
            __this.$router.push({
                name: 'distributor-notice-list',
                query: {
                    noticeType: 1
                }
            })
        },
        get_notice_type() {
            const __this = this;
            web_common_TourismMall_getNoticeTypeEnum().then((res) => {
                __this.noticeTypeList = res.types
            })
        },
        get_notice_detail() {
            const __this = this;
            __this.noticeType = __this.$route.query.noticeType ? __this.$route.query.noticeType : 1;
            Q.when().then(() => {
                return __this.$route.query.noticeId
            }).then((id) => {
                return web_common_TourismMall_getNoticeDetail({noticeId: id});
            }).then((res) => {
                __this.details = res;
                // __this.details.noticeImage = ''
            });
        },
        to_notice_list(row) {
            const __this = this;
            __this.$router.push({
                name: 'distributor-notice-list',
                query: {
                    noticeType: row.type
                }
            })
        }
    },
    created() {
        const __this = this;
        __this.get_notice_type();
        __this.get_notice_detail();
    },
    filters: {
        formatDate(val) {
            return val === '' ? '' : moment(val).format('YYYY-MM-DD HH:mm');
        },
        formatNoticeTitle(val) {
            const param = parseInt(val);
            switch (param) {
                case 1:
                    return '最新公告';
                    break;
                case 2:
                    return '重要公告';
                    break;
                case 3:
                    return '系统公告';
                    break;
            }
        },
    },
}
